<template>
    <div class="page">
        <!--begin header -->
        <header class="header">

            <!--begin navbar-fixed-top -->
            <nav class="navbar navbar-default navbar-fixed-top">

                <!--begin container -->
                <div class="container">

                    <!--begin navbar -->
                    <nav class="navbar navbar-expand-lg">

                        <!--begin logo -->
                        <a class="navbar-brand" href="#">
                            <img src="/images/logoCuadrado.png" alt="Criaturas de Fantasía" title="Criaturas de Fantasía" style="width: 75px" />
                            Criaturas de Fantasía
                        </a>
                        <!--end logo -->

                        <!--begin navbar-toggler -->
                        <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
                        </button>
                        <!--end navbar-toggler -->

                        <!--begin navbar-collapse -->
                        <div class="navbar-collapse collapse" id="navbarCollapse" style="">

                            <!--begin navbar-nav -->
                            <ul class="navbar-nav ml-auto">

                                <li><a href="#temporada1">Temporada 1</a></li>

                            </ul>
                            <!--end navbar-nav -->

                        </div>
                        <!--end navbar-collapse -->

                    </nav>
                    <!--end navbar -->

                </div>
                <!--end container -->

            </nav>
            <!--end navbar-fixed-top -->

        </header>
        <!--end header -->

        <!--begin temporada1 section -->
        <section class="home-section" id="temporada1">

            <!--begin container -->
            <div class="container">

                <!--begin row -->
                <div class="row">

                    <!--begin col-md-6-->
                    <div class="col-md-3">
                        <a @click="goTo('/feronaris')" style="cursor: pointer;">
                            <h1>1. Feronaris</h1>

                            <p>
                                <img src="/images/criaturas/1-Feronaris/feronaris5.png" alt="Feronaris" title="Feronaris" style="width: 100%" />
                            </p>
                        </a>
                    </div>
                    <!--
                    <div class="col-md-3">

                        <h1>2. Nimbón</h1>

                        <p>
                            <img src="/images/criaturas/2-Nimbon/nimbon6.png" alt="Nimbón" title="Nimbón" style="width: 100%" />
                        </p>

                    </div>
                    <div class="col-md-3">

                        <h1>3. Glorafis</h1>

                        <p>
                            <img src="/images/criaturas/3-Glorafis/glorafis5.png" alt="Glorafis" title="Glorafis" style="width: 100%" />
                        </p>

                    </div>
                    <div class="col-md-3">

                        <h1>4. Umbra</h1>

                        <p>
                            <img src="/images/criaturas/4-Umbra/umbra3.png" alt="Umbra" title="Umbra" style="width: 100%" />
                        </p>

                    </div>
                    -->
                    <!--end col-md-6-->

                </div>
                <!--end row -->

            </div>
            <!--end container -->

        </section>
        <!--end temporada1 section -->

        <!--begin footer -->
        <div class="footer">

            <!--begin container -->
            <div class="container footer-top">

                <!--begin row -->
                <div class="row">

                    <!--begin col-md-4 -->
                    <div class="col-md-6 text-center">

                        <i class="pe-7s-map-2"></i>

                        <h5>Sobre mi</h5>

                        <p>Carrer Sant Valentí Nº 14 1ero, <br>Sant Joan de Vilatorrada, Barcelona, España</p>

                        <p><a href="mailto:iam@valentigamez.com">iam@valenatigamez.com</a></p>

                    </div>
                    <!--end col-md-4 -->

                    <!--begin col-md-4 -->
                    <div class="col-md-6 text-center">

                        <i class="pe-7s-comment"></i>

                        <h5>Redes sociales</h5>

                        <!--begin footer_social -->
                        <ul class="footer_social">

                            <li><a href="https://twitter.com/iamvalentigamez" target="_blank"><i class="fab fa-twitter"></i></a></li>

                            <li><a href="https://www.instagram.com/iamvalentigamez/" target="_blank"><i class="fab fa-instagram"></i></a></li>

                        </ul>
                        <!--end footer_social -->

                    </div>
                    <!--end col-md-4 -->

                </div>
                <!--end row -->

            </div>
            <!--end container -->

            <!--begin container -->
            <div class="container-fluid footer-bottom px-0">

                <!--begin row -->
                <div class="row no-gutters mx-0">


                    <!--begin col-md-12 -->
                    <div class="col-md-12 text-center">

                        <p>Copyright © 2023 <span class="template-name">Criaturas de Fantasía</span></p>

                    </div>
                    <!--end col-md-6 -->

                </div>
                <!--end row -->

            </div>
            <!--end container -->

        </div>
        <!--end footer -->
    </div>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
        };
    },
    methods: {
        goTo(route) {
            this.$router.push(route);
        },
    }
};
</script>
