<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'Index'
}
</script>

<style>
body {
    margin: 0;
}
</style>
