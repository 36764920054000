<template>
    <div class="page">
        <!--begin header -->
        <header class="header">

            <!--begin navbar-fixed-top -->
            <nav class="navbar navbar-default navbar-fixed-top">

                <!--begin container -->
                <div class="container">

                    <!--begin navbar -->
                    <nav class="navbar navbar-expand-lg">

                        <!--begin logo -->
                        <a class="navbar-brand" href="#">
                            <img src="/images/logoCuadrado.png" alt="Criaturas de Fantasía" title="Criaturas de Fantasía" style="width: 75px" />
                            Criaturas de Fantasía
                        </a>
                        <!--end logo -->

                        <!--begin navbar-toggler -->
                        <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
                        </button>
                        <!--end navbar-toggler -->

                        <!--begin navbar-collapse -->
                        <div class="navbar-collapse collapse" id="navbarCollapse" style="">

                            <!--begin navbar-nav -->
                            <ul class="navbar-nav ml-auto">

                                <li><a @click="$router.push('/')" style="cursor: pointer;">Temporada 1</a></li>

                            </ul>
                            <!--end navbar-nav -->

                        </div>
                        <!--end navbar-collapse -->

                    </nav>
                    <!--end navbar -->

                </div>
                <!--end container -->

            </nav>
            <!--end navbar-fixed-top -->

        </header>
        <!--end header -->

        <!--begin home section -->
        <section class="home-section" id="home">

            <div class="home-section-overlay"></div>

            <!--begin container -->
            <div class="container">

                <!--begin row -->
                <div class="row">

                    <!--begin col-md-6-->
                    <div class="col-md-6">

                        <h1>1 Feronaris</h1>

                        <p class="hero-text">Su apariencia es la de un felino majestuoso, con un pelaje que cambia de color según las emociones que siente y las estaciones del año. Sus ojos son grandes y brillantes, con una tonalidad ámbar y pequeñas partículas que parecen estrellas titilantes en su interior.</p>

                        <div class="mint">
                            <h2>Supply 20</h2>
                            <h3>Precio 200 MATIC</h3>
                            <p>&nbsp;</p>
                            <select v-model="quantityNFTs">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                            <button v-if="this.walletStatus" @click="getNFT()" >
                                Mint
                            </button>
                            <button v-else @click="connect()">Conectar Wallet</button>
                        </div>

                    </div>
                    <!--end col-md-6-->

                    <div class="col-md-6">
                        <img src="/images/criaturas/1-Feronaris/feronaris2.png" alt="Feronaris" title="Feronaris" style="width: 100%;" />
                    </div>
                </div>
                <!--end row -->

            </div>
            <!--end container -->

        </section>
        <!--end home section -->

        <!--begin section-white -->
        <section class="section-white" id="about">

            <!--begin container-->
            <div class="container">

                <!--begin row-->
                <div class="row">

                    <!--begin col-md-6-->
                    <div class="col-md-6">

                        <video controls style="width: 100%; height: auto;">
                            <source src="/images/criaturas/1-Feronaris/feronarisPresentacio.mp4" type="video/mp4">
                            Tu navegador no soporta los videos mp4.
                        </video>

                    </div>
                    <!--end col-sm-6-->

                    <!--begin col-md-6-->
                    <div class="col-md-6 padding-top-20">

                        <h3>Metas por ventas</h3>

                        <ul class="benefits">
                            <li><i class="fas fa-check"></i> 5 NFTs: Creación de animaciones 2D (Idle, walk, run, attack, die)</li>
                            <li><i class="fas fa-check"></i> 10 NFTs: Creación de modelo 3D compatible con <a href="https://www.mixamo.com" target="_blank">Mixamo</a></li>
                        </ul>

                        <h3>En el mundo</h3>

                        <p>La Feronaris es una criatura respetada y venerada en el mundo, y su presencia es vista como un signo de buena fortuna y equilibrio. Algunos aventureros buscan forjar un vínculo con una Feronaris para aumentar sus propias habilidades y conocimientos, mientras que otros simplemente desean avistar a esta escurridiza y magnífica criatura en su hábitat natural.</p>

                        <h3>Poderes</h3>

                        <ul class="benefits">
                            <li><i class="fas fa-check"></i> <b>Camuflaje emocional</b>: El pelaje de la Feronaris cambia de color según sus emociones, permitiéndole camuflarse con su entorno y pasar desapercibida cuando es necesario. Durante la primavera y el verano, sus colores son más brillantes y vibrantes, mientras que en otoño e invierno adopta tonos más apagados y cálidos.</li>
                            <li><i class="fas fa-check"></i> <b>Empatía mística</b>: La Feronaris posee una habilidad única para sintonizar con las emociones y pensamientos de otros seres vivos, permitiéndole comunicarse con ellos a través de la empatía. Puede calmar a criaturas hostiles, comprender las intenciones de los seres humanos y conectarse con la naturaleza a un nivel más profundo.</li>
                            <li><i class="fas fa-check"></i> <b>Control elemental</b>: La Feronaris tiene la capacidad de controlar los cuatro elementos fundamentales: tierra, agua, fuego y aire. Utiliza estos poderes para proteger su territorio, ayudar a los seres vivos en necesidad y mantener el equilibrio en su entorno.</li>
                            <li><i class="fas fa-check"></i> <b>Regeneración</b>: La Feronaris posee una habilidad regenerativa que le permite sanar rápidamente de heridas y recuperarse de enfermedades. Este poder también se extiende a otros seres vivos, ya que puede transferir parte de su energía vital para sanar a otros.</li>
                            <li><i class="fas fa-check"></i> <b>Vínculo espiritual</b>: La Feronaris puede establecer un vínculo espiritual con un ser humano, creando una relación simbiótica en la que ambas partes se benefician. El humano gana acceso a los poderes elementales y habilidades empáticas de la Feronaris, mientras que la criatura obtiene una conexión más profunda con el mundo humano y la capacidad de comprender mejor sus complejidades.</li>
                        </ul>
                    </div>
                    <!--end col-md-6-->

                </div>
                <!--end row-->

            </div>
            <!--end container-->

        </section>
        <!--end section-white-->

        <!--begin gallery section -->
        <section class="section-white" id="gallery">

            <!--begin container -->
            <div class="container">

                <!--begin row -->
                <div class="row">

                    <!--begin col-md-12 -->
                    <div class="col-md-12 text-center padding-bottom-20">

                        <h2 class="section-title">Galería</h2>

                        <p class="section-subtitle">Descubre las distintas formas que puede adoptar.</p>

                    </div>
                    <!--end col-md-12 -->

                </div>
                <!--end row -->

            </div>
            <!--end container -->

            <!--begin container -->
            <div class="container">

                <!--begin row-->
                <div class="row">

                    <!--begin col-md-6 -->
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <figure class="gallery-insta">
                            <!--begin popup-gallery-->
                            <div class="popup-gallery popup-gallery-rounded portfolio-pic">
                                <a class="popup2" href="/images/criaturas/1-Feronaris/feronaris2.png">
                                    <img src="/images/criaturas/1-Feronaris/feronaris2.png" class="width-100" alt="pic">
                                    <span class="eye-wrapper"><i class="fa fa-search-plus eye-icon" style="font-size: 38px;"></i></span>
                                </a>
                            </div>
                            <!--end popup-gallery-->
                        </figure>
                    </div>
                    <!--end col-md-6 -->

                    <!--begin col-md-6 -->
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <figure class="gallery-insta">
                            <!--begin popup-gallery-->
                            <div class="popup-gallery popup-gallery-rounded portfolio-pic">
                                <a class="popup2" href="/images/criaturas/1-Feronaris/feronaris3.png">
                                    <img src="/images/criaturas/1-Feronaris/feronaris3.png" class="width-100" alt="pic">
                                    <span class="eye-wrapper"><i class="fa fa-search-plus eye-icon" style="font-size: 38px;"></i></span>
                                </a>
                            </div>
                            <!--end popup-gallery-->
                        </figure>
                    </div>
                    <!--end col-md-6 -->

                    <!--begin col-md-4 -->
                    <div class="col-md-4 col-sm-6 col-xs-12">
                        <figure class="gallery-insta">
                            <!--begin popup-gallery-->
                            <div class="popup-gallery popup-gallery-rounded portfolio-pic">
                                <a class="popup2" href="/images/criaturas/1-Feronaris/feronaris4.png">
                                    <img src="/images/criaturas/1-Feronaris/feronaris4.png" class="width-100" alt="pic">
                                    <span class="eye-wrapper"><i class="fa fa-search-plus eye-icon" style="font-size: 38px;"></i></span>
                                </a>
                            </div>
                            <!--end popup-gallery-->
                        </figure>
                    </div>
                    <!--end col-md-4 -->

                    <!--begin col-md-4 -->
                    <div class="col-md-4 col-sm-6 col-xs-12">
                        <figure class="gallery-insta">
                            <!--begin popup-gallery-->
                            <div class="popup-gallery popup-gallery-rounded portfolio-pic">
                                <a class="popup2" href="/images/criaturas/1-Feronaris/feronaris5.png">
                                    <img src="/images/criaturas/1-Feronaris/feronaris5.png" class="width-100" alt="pic">
                                    <span class="eye-wrapper"><i class="fa fa-search-plus eye-icon" style="font-size: 38px;"></i></span>
                                </a>
                            </div>
                            <!--end popup-gallery-->
                        </figure>
                    </div>
                    <!--end col-md-4 -->

                    <!--begin col-md-4 -->
                    <div class="col-md-4 col-sm-6 col-xs-12">
                        <figure class="gallery-insta">
                            <!--begin popup-gallery-->
                            <div class="popup-gallery popup-gallery-rounded portfolio-pic">
                                <a class="popup2" href="/images/criaturas/1-Feronaris/feronaris6.png">
                                    <img src="/images/criaturas/1-Feronaris/feronaris6.png" class="width-100" alt="pic">
                                    <span class="eye-wrapper"><i class="fa fa-search-plus eye-icon" style="font-size: 38px;"></i></span>
                                </a>
                            </div>
                            <!--end popup-gallery-->
                        </figure>
                    </div>
                    <!--end col-md-4 -->

                </div>
                <!--end row -->

            </div>
            <!--end container -->

            <!--begin container -->
            <div class="container">

                <!--begin row -->
                <div class="row">

                    <!--begin col-md-12 -->
                    <div class="col-md-12 text-center padding-top-30">

                        <p class="follow-instagram">Te gusta lo que ves? Sígueme en <a href="https://twitter.com/iamvalentigamez">@iamvalentigamez</a></p>

                    </div>
                    <!--end col-md-12 -->

                </div>
                <!--end row -->

            </div>
            <!--end container -->

        </section>
        <!--end gallery section -->

        <!--begin footer -->
        <div class="footer">

            <!--begin container -->
            <div class="container footer-top">

                <!--begin row -->
                <div class="row">

                    <!--begin col-md-4 -->
                    <div class="col-md-6 text-center">

                        <i class="pe-7s-map-2"></i>

                        <h5>Sobre mi</h5>

                        <p>Carrer Sant Valentí Nº 14 1ero, <br>Sant Joan de Vilatorrada, Barcelona, España</p>

                        <p><a href="mailto:iam@valentigamez.com">iam@valenatigamez.com</a></p>

                    </div>
                    <!--end col-md-4 -->

                    <!--begin col-md-4 -->
                    <div class="col-md-6 text-center">

                        <i class="pe-7s-comment"></i>

                        <h5>Redes sociales</h5>

                        <!--begin footer_social -->
                        <ul class="footer_social">

                            <li><a href="https://twitter.com/iamvalentigamez" target="_blank"><i class="fab fa-twitter"></i></a></li>

                            <li><a href="https://www.instagram.com/iamvalentigamez/" target="_blank"><i class="fab fa-instagram"></i></a></li>

                        </ul>
                        <!--end footer_social -->

                    </div>
                    <!--end col-md-4 -->

                </div>
                <!--end row -->

            </div>
            <!--end container -->

            <!--begin container -->
            <div class="container-fluid footer-bottom px-0">

                <!--begin row -->
                <div class="row no-gutters mx-0">


                    <!--begin col-md-12 -->
                    <div class="col-md-12 text-center">

                        <p>Copyright © 2023 <span class="template-name">Criaturas de Fantasía</span></p>

                    </div>
                    <!--end col-md-6 -->

                </div>
                <!--end row -->

            </div>
            <!--end container -->

        </div>
        <!--end footer -->
    </div>
</template>

<script>
export default {
    name: "1Feronaris",
    data() {
        return {
            walletStatus: false,
            loadNFT: false,
            tokenId: 1,
            quantityNFTs: 1
        };
    },
    mounted() {
        setInterval(async () => {
            this.walletStatus = this.walletManager.walletStatus;

            if(!this.loadNFT) {
                this.loadNFT = true;
            }
        }, 100);
    },
    methods: {
        async connect() {
            let err = await this.walletManager.connectToMetamask();
            if (err != "") {
                console.log(err);
            }
        },
        async getNFT() {
            let signer = await this.walletManager.web3Global.getSigner();
            let nftSigner = this.walletManager.nft.connect(signer);
            let signerAddress = await signer.getAddress();

            try {
                await nftSigner.mint(signerAddress, this.tokenId, this.quantityNFTs, [], {
                    // value: this.walletManager.ethers.utils.parseUnits('1', 'ether'),
                    value: this.walletManager.ethers.utils.parseUnits((200 * this.quantityNFTs).toString(), 'ether'),
                    gasLimit: 600000
                });
            } catch (e) {
                console.log(e);    
            }
        }
    }
};
</script>

<style scoped>
.mint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mint > h1 {
    text-align: center;
}

.mint > p {
    margin-top: 0;
    margin-bottom: 0;
}

.mint > select {
    border-radius: 10px;
    padding: 10px;
    background-color: transparent;
    color: #454545;
    font-size: 24px;
    font-weight: bold;
    border: 3px solid white;
    margin-bottom: 30px;
}


.mint > h2 {
    margin-bottom: 1px;
    margin-top: 1px;
}
.mint > h5 {
    margin-bottom: 1px;
    margin-top: 1px;
}

.mint > h3 {
    margin-bottom: 5px;
    margin-top: 5px;
}

.mint > button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: #454545;
    border: 3px solid white;
    border-radius: 10px;
    font-size: 36px;
    margin-bottom: 20px;
    padding: 5px 40px;
}

.mint > button:hover {
    cursor: pointer;
}

.mint > a, .mint > a:hover, .mint > a:active {
    text-decoration: none;
    color: #454545;
}

@media (max-width: 600px) {
    .mint {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .page {
        display: block;
    }
}
</style>
