import VueRouter from 'vue-router';

import Home from "./pages/Home";
import Feronaris from "./pages/1Feronaris";

const routes = [{
    path: '/',
    name: 'home',
    component: Home
},
{
    path: '/feronaris',
    name: 'feronaris',
    component: Feronaris
}];

const router = new VueRouter({
    history: true,
    mode: 'history',
    routes
});

export default router
